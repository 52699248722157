<template>
  <div>
    <label for="health-history-illnesses">Illnesses</label>
    <InputFormContainer
      meta-key="health-history-illnesses"
      @save="saveChanges"
      :height="100"
    />

    <label for="health-history-illnesses-impact"
      >They impacted me in this way:</label
    >
    <InputFormContainer
      meta-key="health-history-illnesses-impact"
      @save="saveChanges"
      :height="100"
    />

    <label for="health-history-surgeries"
      >Inpatient &amp; outpatient surgeries? Were they elective or
      prescribed?</label
    >
    <InputFormContainer
      meta-key="health-history-surgeries"
      @save="saveChanges"
      :height="100"
    />

    <label for="health-history-broken-bones"> Broken bones: (how) </label>
    <InputFormContainer
      meta-key="health-history-broken-bones"
      @save="saveChanges"
      :height="100"
    />

    <div class="row">
      <div class="col-md-6">
        <label for="health-history-tonsils-removed">
          Tonsils removed (when)
        </label>
        <InputFormContainer
          type="input"
          meta-key="health-history-tonsils-removed"
          @save="saveChanges"
          :height="100"
        />
      </div>
      <div class="col-md-6">
        <label for="health-history-appendix-removed">
          Appendix removed (when)
        </label>
        <InputFormContainer
          type="input"
          meta-key="health-history-appendix-removed"
          @save="saveChanges"
          :height="100"
        />
      </div>
    </div>
    <label for="health-history-eye-health">
      Eye health? I started wearing corrective lenses when I was...
    </label>
    <InputFormContainer
      meta-key="health-history-eye-health"
      @save="saveChanges"
      :height="100"
    />

    <label for="health-history-pregnancy-issues">
      Issues getting pregnant:
    </label>
    <InputFormContainer
      meta-key="health-history-pregnancy-issues"
      @save="saveChanges"
      :height="100"
    />

    <label for="health-history-illnesses-relatives">
      I am aware of the following health issues among my relatives:
    </label>
    <InputFormContainer
      meta-key="health-history-illnesses-relatives"
      @save="saveChanges"
      :height="100"
    />

    <label for="health-history-add-to-journal">
      <InputFormContainer
        type="checkbox"
        meta-key="health-history-add-to-journal"
        @save="saveChanges"
        :height="100"
        placeholder="Add to Legacy Journal"
      />
    </label>
  </div>
</template>

<script>
import InputFormContainer from "../InputFormContainer.vue";
export default {
  components: {
    InputFormContainer,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    saveChanges({ metaKey, content }) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },
  },
};
</script>

<style scoped>
div .input-form {
  height: 500px;
}
label {
  margin-top: 20px;
}
</style>